<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                    &gt; <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                    <template v-if="account">
                    &gt; <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ account.name }}</router-link>
                    &gt; <router-link :to="{ name: 'account-search-brandprofile', params: { accountId: this.$route.params.accountId } }">BrandProfiles</router-link>
                    </template>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ brandProfile.name }}</h1>
                < ! - - <h1 class="text-caption font-weight-light text-center">{{ brandProfile.status }}</h1> - - >
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="account">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card>
                        <v-app-bar color="teal darken-2" dark flat dense>
                            <v-app-bar-title>BrandProfile Settings</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text>

                        <template v-if="!account.brandprofile">
                            <p class="mb-0 pb-0">
                                You haven't set the BrandProfile yet for this account.
                            </p>

                            <!-- TODO: webauthz with brandprofile.org to select a brandprofile and then we can verify it with the access token -->
                        </template>

                        <template v-if="account.brandprofile && account.link">
                            <p class="mb-0 pb-0 mt-4" v-if="account.link.subscribe || account.link.unsubscribe">
                                This is the link to your public BrandProfile:
                            </p>
                            <p class="mb-0 pb-0" v-if="account.link.brandprofile">
                                <a :href="account.link.brandprofile" target="_blank">{{ account.brandprofile }}</a>
                            </p>

                            <p class="mb-0 pb-0 mt-4" v-if="account.link.subscribe || account.link.unsubscribe">
                                You can also share the following links:
                            </p>
                            <p class="mb-0 pb-0" v-if="account.link.subscribe">
                                <a :href="account.link.subscribe" target="_blank">Subscribe link</a>
                            </p>
                            <p class="mb-0 pb-0" v-if="account.link.unsubscribe">
                                <a :href="account.link.unsubscribe" target="_blank">Unsubscribe link</a>
                            </p>

                        </template>
                        <!-- <p class="text-overline mb-0 mt-2">BrandProfile alias</p>
                        <p class="mb-0 pb-0">
                            {{ brandProfile.alias }}
                        </p>

                        <p class="text-overline mb-0 mt-8">BrandProfile status</p>
                        <template v-if="brandProfile.status === 'new'">
                        <p class="mb-0 pb-0">
                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                            Not verified
                        </p>
                        <p class="mb-0 pb-0 text-caption">
                            You must prove ownership of the BrandProfile before it can be used with Emplus.
                        </p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-verify-brandprofile', params: { accountId: this.$route.params.accountId, brandProfile: this.$route.params.brandprofileId } }">Verify BrandProfile ownership</router-link>
                        </p>
                        </template> -->
                        <!-- <p class="mb-0 pb-0 text-caption">
                            You must create a DNS record with this code to prove ownership of the BrandProfile.
                        </p> -->
                        <!-- </template> -->

                        <!-- TODO: rename this section from 'DNS' to "anti-spam" settings for DMARC/DKIM/SPF, and here we can report on status of BrandProfile if it's propertly configured for emplus or not... so on server a "check BrandProfile" API with items like 'DMARC', 'DKIM', 'SPF', etc. so each item can be checked separately , and we'd call them all from here adn report the result (for each one, checkbox or not) -->

                        <!-- <p class="text-overline mb-0 mt-8">DNS</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-edit-dns', params: { accountId: this.$route.params.accountId, brandProfile: this.$route.params.brandprofileId } }">Edit DNS records</router-link>
                        </p> -->

                        <!-- <p class="text-overline mb-0 mt-8">Other</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-delete-brandprofile', params: { accountId: this.$route.params.accountId, brandProfile: this.$route.params.brandprofileId } }">How to delete this BrandProfile</router-link>
                        </p> -->

                        </v-card-text>
                    </v-card>

                    <v-expansion-panels class="mt-8 mb-6" v-if="isPermitServiceAdmin">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="teal--text text--darken-2"/></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-btn text color="teal darken-2" class="px-0" @click="editBrandProfileDialog = true">Edit BrandProfile</v-btn>
                                <!-- <router-link :to="{ name: 'service-admin' }">Manage Emplus</router-link> -->
                                <!-- Edit BrandProfile status -->
                                <!-- <v-btn text color="teal darken-2" class="px-0" @click="editBrandProfileStatus('verified')" v-if="brandProfile.status !== 'verified'">Verify BrandProfile</v-btn>
                                <v-btn text color="teal darken-2" class="px-0" @click="editBrandProfileStatus('new')" v-if="brandProfile.status === 'verified'">Suspend BrandProfile</v-btn> -->
                                <p>
                                    <router-link :to="{ name: 'account-search-brandprofile', params: { accountId: this.$route.params.accountId } }">Manage BrandProfiles</router-link>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-dialog v-model="editBrandProfileDialog" max-width="600">
                    <v-card tile elevation="4" class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Edit account BrandProfile setting</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form class="px-2" @submit.prevent="saveBrandProfile">
                            <v-row>
                            <v-text-field v-model="editableBrandProfile" label="BrandProfile" ref="editableBrandProfileInput"></v-text-field>
                            </v-row>
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="teal darken-2 white--text" @click="saveBrandProfile" :disabled="!isEditBrandProfileFormComplete">
                                <span>Save</span>
                            </v-btn>
                            <v-btn text color="grey" @click="editBrandProfileDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import RealmList from '@/components/brandProfile-dashboard/RealmList.vue';

export default {
    components: {
        // RealmList,
    },
    data: () => ({
        account: null,
        brandProfile: null,
        error: null,
        whois: null,
        whoisDialog: false,
        nameserverList: null,
        status: null,

        editBrandProfileDialog: false,
        editableBrandProfile: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        accountName() {
            return this.isViewReady ? this.account?.name ?? 'Unknown' : '';
        },
        registrarName() {
            return this.whois?.info?.Registrar?.join(' / ') ?? 'Unknown';
        },
        registrarURL() {
            try {
                const list = this.whois?.info?.['Registrar URL'];
                if (Array.isArray(list) && list.length > 0) {
                    const inputURL = new URL(list[0]);
                    return inputURL.toString();
                }
                return null;
            } catch (err) {
                return null;
            }
        },
        registrarDnsNameservers() {
            return this.whois?.info?.['Name Server'] ?? [];
        },
        // TODO: move this getter to vuex so we don't have to redefine it everywere... we'll just use mapGetters
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isViewReady() {
            return this.account !== null && this.brandProfile !== null;
        },
        isEditBrandProfileFormComplete() {
            return typeof this.editableBrandProfile === 'string' && this.editableBrandProfile.length > 0;
        },
    },
    watch: {
        editBrandProfileDialog(newValue) {
            if (newValue && this.account?.brandprofile) {
                this.editableBrandProfile = this.account.brandprofile;
            }
            this.$nextTick(() => {
                setTimeout(() => { this.activate('editableBrandProfileInput'); }, 1);
            });
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadBrandProfile() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadBrandProfile: true });
                const response = await this.$client.account(this.$route.params.accountId).brandProfile.get({ id: this.$route.params.brandprofileId });
                console.log(`brandProfile/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.brandProfile = response;
                } else {
                    // TODO: redirect back to BrandProfile list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load BrandProfile', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadBrandProfile: false });
            }
        },
        async editBrandProfileStatus(newValue) {
            // NOTE: server does access control check for this, only service admin may use the function
            try {
                this.$store.commit('loading', { editBrandProfileStatus: true });
                const response = await this.$client.account(this.$route.params.accountId).brandProfileStatus.edit({ brandprofile_id: this.brandProfile?.id }, { status: newValue });
                console.log(`brandProfile/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$set(this.brandProfile, 'status', newValue);
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'BrandProfile status updated', message: `New status: ${newValue}` });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update BrandProfile status' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update BrandProfile status' });
            } finally {
                this.$store.commit('loading', { editBrandProfileStatus: false });
            }
        },
        async saveBrandProfile() {
            // NOTE: server does access control check for this, only service admin may use the function
            try {
                this.$store.commit('loading', { saveBrandProfile: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.edit({ brandprofile: this.editableBrandProfile });
                console.log(`brandProfile/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'BrandProfile updated', message: `New status: ${this.editableBrandProfile}` });
                    this.editBrandProfileDialog = false;
                    this.loadAccount(); // we could use $set for brandprofile but we also need to refresh the links
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update BrandProfile' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update BrandProfile' });
            } finally {
                this.$store.commit('loading', { saveBrandProfile: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        // this.loadBrandProfile();
    },
};
</script>
